<template>
    <div class=" w-screen h-screen">
        <CoohomeIframe></CoohomeIframe>
    </div>
</template>
<script>
import CoohomeIframe from '@/components/CoohomeIframe.vue';
export default {
    components: { CoohomeIframe }
}
</script>