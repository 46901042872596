<template>
  <section class="p-5" style="background-color: #e9e9e9">
    <div class="max-w-4xl mx-auto text-center">
      <div class="text-center text-5xl font-mono">Our Story</div>
      <br />
      <div class="bg-black h-0.5 w-40 rounded-full mx-auto"></div>
      <br />
      <div
        class="text-xl italic flex flex-col gap-3 font-sans leading-8"
        style="color: #212535"
      >
        <div>
          <span class="text-3xl font-bold"> "</span>
          Everything is seen only when light enters our eyes. But there are some
          things we can see even without this entry of light. Our dreams and
          mental images.
          <span class="text-3xl font-bold"> "</span>
        </div>
        <div>
          Phosphenes are the luminous floating stars, zigzags, swirls, spirals,
          squiggles, and other shapes that you see when closing your eyes tight
          and pressing them with your fingers. ... These flashes of colorful
          light that are produced from inside the eye rather than from an
          outside light source.
        </div>
        <div>
          <span class="text-3xl font-bold">"</span>An impression of light that
          occurs without light entering the eye<span class="text-3xl font-bold">
            "</span
          >
        </div>
        <div>
          The word phosphene comes from the Greek words phōs (light) and
          phainein (to show).
        </div>
        <div>
          <span class="text-3xl font-bold"> "</span> Our firm is the source of
          ‘light to show’ your mental images and design them into a concrete
          reality.<span class="text-3xl font-bold"> "</span>
        </div>
        <div>
          We focus on pragmatic design, in an approach of experiential space
          planning with a parametric touch, to create climate responsive
          sustainable habitable structures with comfort and aesthetics. Our
          Integrated architectural approach ranges from residential, commercial,
          hospitality, industrial and high-rise designs with elegant interiors
          and landscapes.
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>