<template >
    <div class=" w-screen h-screen">
        <ArtboxIframe></ArtboxIframe>
    </div>
</template>
<script>
import ArtboxIframe from '@/components/ArtboxIframe.vue';
export default {
    components: { ArtboxIframe }
}
</script>