<template>
  <section class="p-5 max-w-6xl text-center mx-auto">
    <!-- <div class="text-center text-5xl font-mono">Featured</div>
    <br />
    <div class="bg-black h-0.5 w-40 rounded-full mx-auto"></div>
    <br /> -->
    <div class="flex justify-center flex-wrap">
      <div class="w-full p-2 md:w-1/2" v-for="(item, index) in projectsl" :key="index">
        <img class="
            transition
            duration-500
            ease-in-out
            bg-blue-600
            transform
            hover:-translate-y-1 hover:scale-110
          " :src="require(`../${item}`)" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  setup() {
    const projectsl = [
      "assets/features/1_page-0001.jpg",
      "assets/features/2_page-0001.jpg",
      "assets/features/Arch Dias_page-0001.jpg",
    ];
    // const ty = "assets/projects/ty.webp";
    return { projectsl};
  },
};
</script>

<style lang="scss" scoped></style>